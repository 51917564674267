import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Anchor from "@components/Anchor"
import Container from "@components/container"
import { Heading2 } from "@styles/ui"

// Assets
import logo from "@images/logos/xywav-logo.svg"

const SiteMap = ({ location }) => {
  const sitemapLinks = [
    {
      url: "/idiopathic-hypersomnia/",
      text: "Homepage",
    },
    {
      url: "/idiopathic-hypersomnia/what-is-ih/",
      text: "What is IH?",
    },
    {
      text: "Why XYWAV",
      subLinks: [
        {
          url: "/idiopathic-hypersomnia/what-is-xywav/",
          text: "What is XYWAV?",
        },
        {
          url: "/idiopathic-hypersomnia/xywav-efficacy/",
          text: "Study Results with XYWAV",
        },
        {
          url: "/idiopathic-hypersomnia/side-effects/",
          text: "Possible Side Effects",
        },
        {
          url: "/idiopathic-hypersomnia/faq/",
          text: "Frequently Asked Questions",
        },
      ],
    },
    {
      text: "Getting XYWAV",
      subLinks: [
        {
          url: "/idiopathic-hypersomnia/talking-to-your-doctor/",
          text: "Talking With Your Doctor",
        },
        {
          url: "/idiopathic-hypersomnia/dosing/",
          text: "Taking XYWAV",
        },
        {
          url: "/idiopathic-hypersomnia/getting-xywav/",
          text: "Getting XYWAV",
        },
      ],
    },
    {
      text: "Support & Resources",
      subLinks: [
        {
          url: "/idiopathic-hypersomnia/jazzcares-for-xywav/",
          text: (
            <>
              JazzCares<sup>&reg;</sup> Savings & Financial Support
            </>
          ),
        },
        {
          url: "/idiopathic-hypersomnia/patient-support-programs/",
          text: (
            <>
              JazzCares<sup>&reg;</sup> Patient Support Programs
            </>
          ),
        },
        {
          url: "/idiopathic-hypersomnia/resources/",
          text: "Resources & Videos",
        },
      ],
    },
    {
      url: "/idiopathic-hypersomnia/stay-connected/",
      text: "Unlock More Support",
    },
  ]
  return (
    <Layout
      location={location}
      isiBgColour="white"
      footerBgColour="white"
      headerType="ih"
      footerType="ih"
      mainBgColour="lilac"
      copyright={
        <>
          &copy; 2023 Jazz Pharmaceuticals plc{" "}
          <nobr>US-XYW-2200433 Rev1123</nobr>
        </>
      }
    >
      <Seo
        title="User Sitemap | XYWAV"
        description="Explore the Xywav.com sitemap. See XYWAV™ (calcium, magnesium, potassium, and sodium oxybates) oral solution Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <section tw="relative py-12 pt-20 md:(pt-36) xl:(pt-44 pb-16)">
        <img
          src={logo}
          width="630px"
          alt=""
          tw="hidden xl:(block absolute right-[-100px] top-[80px])"
        />
        <Container>
          <h1 css={[Heading2, tw`text-5xl mb-8 lg:(mb-10 text-6xl)`]}>
            Sitemap
          </h1>
          <div>
            <ul tw="xl:(text-xl)">
              {sitemapLinks.map((link, i) => {
                return link.subLinks ? (
                  <li key={i} tw="font-black mt-4.5 xl:(mt-6)">
                    {link.text}
                    <ul tw="font-normal">
                      {link.subLinks.map((sublink, i) => {
                        return (
                          <li key={i} tw="py-3 ml-6">
                            <Anchor colour="merlin" link={sublink.url}>
                              {sublink.text}
                            </Anchor>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                ) : (
                  <li key={i} tw="font-black mt-4.5 xl:(mb-5)">
                    <Anchor colour="merlin" link={link.url}>
                      {link.text}
                    </Anchor>
                  </li>
                )
              })}
            </ul>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default SiteMap
